exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-featured-index-tsx": () => import("./../../../src/pages/featured/index.tsx" /* webpackChunkName: "component---src-pages-featured-index-tsx" */),
  "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-curious-creatures-marvellous-monsters-index-mdx": () => import("./../../../src/pages/featured/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/curious-creatures-marvellous-monsters/index.mdx" /* webpackChunkName: "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-curious-creatures-marvellous-monsters-index-mdx" */),
  "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-finale-index-mdx": () => import("./../../../src/pages/featured/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/finale/index.mdx" /* webpackChunkName: "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-finale-index-mdx" */),
  "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-lemi-index-mdx": () => import("./../../../src/pages/featured/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/lemi/index.mdx" /* webpackChunkName: "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-lemi-index-mdx" */),
  "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-manawatia-a-matariki-index-mdx": () => import("./../../../src/pages/featured/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/manawatia-a-matariki/index.mdx" /* webpackChunkName: "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-manawatia-a-matariki-index-mdx" */),
  "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-mataaho-collective-index-mdx": () => import("./../../../src/pages/featured/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/mataaho-collective/index.mdx" /* webpackChunkName: "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-mataaho-collective-index-mdx" */),
  "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-mojo-index-mdx": () => import("./../../../src/pages/featured/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/mojo/index.mdx" /* webpackChunkName: "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-mojo-index-mdx" */),
  "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-new-photography-index-mdx": () => import("./../../../src/pages/featured/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/new-photography/index.mdx" /* webpackChunkName: "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-new-photography-index-mdx" */),
  "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-pacific-sisters-index-mdx": () => import("./../../../src/pages/featured/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/pacific-sisters/index.mdx" /* webpackChunkName: "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-pacific-sisters-index-mdx" */),
  "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-robin-white-index-mdx": () => import("./../../../src/pages/featured/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/robin-white/index.mdx" /* webpackChunkName: "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-robin-white-index-mdx" */),
  "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-tatau-index-mdx": () => import("./../../../src/pages/featured/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/tatau/index.mdx" /* webpackChunkName: "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-tatau-index-mdx" */),
  "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-terracota-warriors-index-mdx": () => import("./../../../src/pages/featured/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/terracota-warriors/index.mdx" /* webpackChunkName: "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-terracota-warriors-index-mdx" */),
  "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-tommy-millions-index-mdx": () => import("./../../../src/pages/featured/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/projects/tommy-millions/index.mdx" /* webpackChunkName: "component---src-pages-featured-mdx-frontmatter-slug-tsx-content-file-path-projects-tommy-millions-index-mdx" */),
  "component---src-pages-gallery-2-tsx": () => import("./../../../src/pages/gallery2.tsx" /* webpackChunkName: "component---src-pages-gallery-2-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

